import React, { useEffect } from "react";




import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import ContactUs from '../components/ContactUs/ContactUs';
import contactUsBannerImg from '../../utils/assets/home/bg1.png';




const Contact = props => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])
    const bannerSectionInfo =
    {
       cardTitle: "CONTACT US",
       cardButtonText: "",
       lengendBackground: contactUsBannerImg
    }

    return (
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Contact"/>
            <ContactUs />

        </React.Fragment>
    );
};

export default Contact;