import React, {useEffect}from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import Benefits from "../components/Benefits/Benefits";
import Faq from '../components/Faq/Faq';

import aboutUsBannerImg from '../../utils/assets/home/bg1.png';
import coupelShopping from '../../utils/assets/aboutUs/coupel-shopping.png';
import onbgImg from '../../utils/assets/aboutUs/onbg1.png';
import mobileManSittingWithHisLaptop from '../../utils/assets/aboutUs/mobile-man-sitting-with-his-laptop-redeeming-rebates.png';
import tabletManSittingWithHisLaptop from '../../utils/assets/aboutUs/tablet-man-sitting-with-his-laptop-redeeming-rebates.png';
import desktopManSittingWithHisLaptop from '../../utils/assets/aboutUs/desktop-man-sitting-with-his-laptop-redeeming-rebates.png';

const AboutUs = props => {

    useEffect(()=>{
      window.scroll(0,0);
    },[])
    const bannerSectionInfo =
     {
        cardTitle: "What is our identity?",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg,
        cardImg :onbgImg,
        mobileBackground : mobileManSittingWithHisLaptop,
        tabletBackground : tabletManSittingWithHisLaptop,
        desktopBackground : desktopManSittingWithHisLaptop
     }

     const productDescription = {
        title : "HOW DOES IT WORK?",
        img : coupelShopping

     }
          
    return(
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
           <SectionInfo title = "Benefits" color="#BF8AAD"/>
           <Benefits/>
           <Faq/> 
        </React.Fragment>
    );
};

export default AboutUs;