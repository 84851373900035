import React, { useEffect } from "react";


import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';


// import bannerImg from '../../utils/assets/home/bg1.png';
// import onbgImg from '../../utils/assets/price/onbg1.png';
import AvailablePlan from "../components/AvailablePlan/AvailablePlan";
import mobileWomanSatisfiedWithHerDisocunts from '../../utils/assets/price/mobile-woman-satisfied-with-using-her-discounts.png';
import tabletWomanSatisfiedWithHerDisocunts from '../../utils/assets/price/tablet-woman-satisfied-with-using-her-discounts.png';
import desktopWomanSatisfiedWithHerDisocunts from '../../utils/assets/price/desktop-woman-satisfied-with-using-her-discounts.png';

const Price = props => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])
     const bannerSectionInfo =
     {
        cardTitle: "Discover our services",
        cardBodyText: `Enter a world of countless benefits and unlimited possibilities. Do it now and get your membership!`,
        cardButtonText: "Try Now",
        mobileBackground : mobileWomanSatisfiedWithHerDisocunts,
        tabletBackground : tabletWomanSatisfiedWithHerDisocunts,
        desktopBackground: desktopWomanSatisfiedWithHerDisocunts
     }

    return (
        <React.Fragment>
             <WelcomeSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <AvailablePlan/>
        </React.Fragment>
    );
};

export default Price;