import React from "react";
import './SectionDivider.css'


const SectionDivider = ({ props }) => {

    return (
        <section className="SectionDivider__section-container">
            <div className="SectionDivider__inner-container">
                <div className="SectionDivider__icons-container">
                    <div className="SectionDivider__inner-icons-container">
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon1} alt="doctor-icon" className="SectionDivider__icon" />
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                            <p>{props.description1}</p>
                        </div>
                    </div>

                    <div className="SectionDivider__inner-icons-container">
                     
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon2} alt="doctor-icon" className="SectionDivider__icon" />
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                            <p>{props.description2}</p>
                        </div>
                    </div>

                    <div className="SectionDivider__inner-icons-container">
                   
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon3} alt="doctor-icon" className="SectionDivider__icon"/>
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                          <p>{props.description3}</p>
                        </div>
                    </div>

                    <div className="SectionDivider__inner-icons-container">
                    
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon4} alt="doctor-icon" className="SectionDivider__icon"/>
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                          <p>{props.description4}</p>
                        </div>
                    </div>

                    <div className="SectionDivider__inner-icons-container">
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon5} alt="doctor-icon" className="SectionDivider__icon"/>
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                          <p>{props.description5}</p>
                        </div>
                    </div>

                    <div className="SectionDivider__inner-icons-container">
                        <div className="SectionDivider__icon-container">
                            <div>
                                <img src={props.icon6} alt="doctor-icon" className="SectionDivider__icon"/>
                            </div>
                        </div>
                        <div className="SectionDivider__text-container">
                          <p>{props.description6}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionDivider;