import React from 'react';

import './PaymentInfo.css'
import PaymentForm from '../PaymentForm/PaymentForm';


const PaymentInfo = () => {
    return (
        <section className='PaymentInfo__main-section'>
            <div className='PaymentInfo__main-title-container'>
                <h1 className="PaymentInfo__title">Become a member today</h1>
            </div>
            <div className='PaymentInfo__payment-form-container'>
                <PaymentForm></PaymentForm>
            </div>
        </section>
    );
};

export default PaymentInfo;
