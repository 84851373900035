import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import MembershipTerms from '../components/MembershipTerms/MembershipTerms';

import termsOfServiceBannerImg from '../../utils/assets/home/bg1.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

const TermsOfService = props => {

    useEffect(()=>{
        window.scroll(0,0);

        return()=>{
            sessionStorage.clear();
        }
      },[])
    const bannerSectionInfo =
     {
        cardTitle: "User Agreement",
        cardBodyText: `Here you will find all the information you need about our membership services. If you have any questions or concerns, we are here to help!`,
        cardButtonText: "MAKE A CALL",
        lengendBackground: termsOfServiceBannerImg
     }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Terms of service"/>
            <MembershipTerms/>
        </React.Fragment>
    );
};

export default TermsOfService;