import React, { useEffect } from "react";
import LoginForm from '../components/LoginForm/LoginForm';

const Login = () => {
    useEffect(()=>{
        window.scroll(0,0);
      },[])

    return (
        <React.Fragment>
            <LoginForm />
        </React.Fragment>
    );
}
export default Login;