import React, { useEffect } from "react";


import privacyPolicyBannerImg from '../../utils/assets/home/bg1.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import PrivacyTerms from "../components/PrivacyTerms/PrivacyTerms";


const PrivacyPolicy = props => {

    useEffect(()=>{
        window.scroll(0,0);
        return()=>{
            sessionStorage.clear();
        }
      },[])
    
    const bannerSectionInfo =
     {
        cardTitle: "Learn how we protect your information",
        cardBodyText: `At Club Save, we take the protection of your personal information very seriously.`,
        cardButtonText: "CALL US",
        lengendBackground: privacyPolicyBannerImg
     }

    return (
        <React.Fragment>
        <WelcomeSection props={bannerSectionInfo}/>
        <NavegationInfo page="Privacy policy"/>
        <PrivacyTerms/>

        </React.Fragment>
    );
};

export default PrivacyPolicy;