import React from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import bannerImg from '../../utils/assets/home/bg1.png';
import Error404 from "../components/Error404/Error404";
const NotFound = () =>{

    const bannerSectionInfo =
    {
       cardTitle: "Nothing to worry about",
       cardButtonText: "",
       lengendBackground: bannerImg
    }

    return (
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo}/>
            <Error404/>

        </React.Fragment>
    );
}

export default NotFound;