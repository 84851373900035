import { API } from '../../helpers/constants/apiUrl';
import { WEBSITE } from '../constants/websiteData';


export const sendCancelServiceEmail = (dataFormForm) => {
    const fullMessage = `The order id is: ${dataFormForm.orderId} \n
                         The phone number is: ${dataFormForm.phoneNumber}\n
                         The cancelation reason is: ${dataFormForm.reasonForCancellation}\n
                         The user message is : ${dataFormForm.message === 'undefined' || dataFormForm.message === '' ? "The customer did not leave a message": dataFormForm.message} 
                        `
    const formData = new FormData();
    formData.append('website', WEBSITE.DOMAIN);
    formData.append('message', fullMessage);
    formData.append('TOKEN', process.env.REACT_APP_CONTACT_TOKEN);

    return fetch(API.CONTACT_FORM, {
        method: 'POST',
        body: formData,
    })
        .then(response => response.text())
        .then(data => {
            return data; 
        })
        .catch(error => {
            console.error('Error:', error);
            throw error; 
        });
}