import React, {useState} from "react";
import Swal from "sweetalert2";
import { sha256, sha224 } from 'js-sha256';

import './LoginForm.css';
const LoginForm = () => {
    const [memberID, setMemberID] = useState('');
    const [password, setPassword] = useState('');


    const handelSubmit = ( e ) =>{
        e.preventDefault();
        if (memberID === '' || password === ''){
            Swal.fire({
                title: "Empty fields",
                text: "Please fill out all the fields",
                icon: "error",
                confirmButtonColor : '#f25116'
              });
              return;
        }
        else{
            let token = `${process.env.REACT_APP_ENJOY_DEALS_TOKEN}${memberID}`;
            let result = sha256(token);
            let linkref = `https://vip.enjoymydeals.com/director?cvt=${result}`;
            if(memberID === '2251333') {
                linkref = `https://vip.enjoymydeals.com/director?cvt=${process.env.REACT_APP_ENJOY_DEALS_CTV}`;
            }
            window.location.href = linkref;
        }
    }


    return ( 
    <div className="LoginForm__main-container">
        <div className="LoginForm__inner-container">
            <div className="LoginForm__form-container">
                <h1>Login to your account</h1>
                <form onSubmit={handelSubmit} className="LoginForm__form">
                    <div className="LoginForm__input-container">
                    <label htmlFor='memberid' className='LoginForm__LabelForms'>Member ID:</label> <br/>
                            <input 
                                className = 'LoginForm__inputForms'
                                type='text' 
                                id='memberid' 
                                onChange={(e) => setMemberID(e.target.value)} 
                            />
                    </div>
                    <div className="LoginForm__input-container">
                    <label htmlFor='pw' className="LoginForm__LabelForms">Password:</label><br/>
                            <input 
                                className = 'LoginForm__inputForms'
                                type='password'
                                id='pw' 
                                onChange={(e) => setPassword(e.target.value)} 
                            />
                    </div>
                    <div className="LoginForm__main-button-container">
                        <div className="LoginForm__inner-button-container">
                            <button type="submit" className="LoginForm__submit-button">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> 
    );
}
 
export default LoginForm;