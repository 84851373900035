import React, {useState, useEffect} from "react";
import { useHistory, useLocation } from 'react-router-dom';
import './WelcomeSection.css';
import generalBackgroundImg from '../../../utils/assets/home/bg1.png';

const WelcomeSection = ({ props }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(()=>{
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[]);

    const selectImageUrl = () => {
        if (windowWidth <= 420.99) {
                console.log(props.mobileBackground)
            return props.mobileBackground;

        } else if (windowWidth >= 421 && windowWidth <= 720.99) {
            
            return props.tabletBackground;

        } else if (windowWidth >= 721 && windowWidth <= 991.99) {

            return props.desktopBackground;
        }
        else{
            return props.desktopBackground;
        }
    };




    return (
        window.location.pathname === '/'||
        window.location.pathname === '/home' ||
        window.location.pathname === '/about-us' || 
        window.location.pathname === '/price' ||
        window.location.pathname === '/checkout' ? 
        ( 
        <section className="WelcomeSection__main-section-container" style={{backgroundImage: `url(${generalBackgroundImg})`}} > 
           <div className="WelcomeSection__inner-section-container" style={{backgroundImage: `url(${selectImageUrl()})`}}> 
           </div>
           <div className="WelcomeSection__card-info-component-container">
                <CardInfo props={props}></CardInfo>
           </div> 
        </section> 
        )
        :
        (
            <section className="WelcomeSection__main-section-container-without-bg" style={{backgroundImage: `url(${props.lengendBackground})`}}> 
                <div className="WelcomeSection__inner-section-container-without-bg" >
                    <h1>
                        {props.cardTitle}
                    </h1>
                </div>                
            </section> 
        )
    );
};


const CardInfo = ({ props }) => {

    const history = useHistory();
    const location = useLocation();
    const cellphone = "8884743195";



    let cardBodyText;

    switch (window.location.pathname) {
        case '/':
            if (props.cardBannerPosition === 1) {
                cardBodyText = (
                    <p>
                        Our Savings Storms program gives you premium
                        discounts on purchases that can&nbsp;
                        <span className="CardInfo__important-text">save you hundreds
                            of dollars!
                        </span>
                    </p>
                )
            }
            else if (props.cardBannerPosition === 2) {
                cardBodyText = (
                    <p>
                        Our Rewards Program offers a range of exclusive
                        benefits and services. track treatments,&nbsp;
                        <span className="CardInfo__important-text">start saving today by
                        enrolling to our monthly membership.
                        </span>
                    </p>
                )
            }
            else {
                cardBodyText = (
                    <p>
                        Save time and money with our exclusive telehealth
                        plans. Get care whenever and wherever you need it, &nbsp;
                        <span className="CardInfo__important-text">just a phone call away!</span>
                    </p>
                )
            }
            break;

        case '/price':

            cardBodyText = (
                <p>
                    Enter a world of countless benefits and unlimited
                    possibilities.&nbsp;<span className="CardInfo__important-text">Do it now and get your membership!</span>
                </p>
            )
            break;

        case '/contact-us':
            cardBodyText = (
                <p>
                    Join our community and watch your savings grow
                    by thousands of dollars each year.&nbsp;<span className="CardInfo__important-text">Do it now, you
                        are just a phone call away!
                    </span>
                </p>
            )
            break;

        case '/cancel-service':
            cardBodyText = (
                <p>
                    If you want to cancel, it's a simple process. You can
                    either fill out the cancellation form or call us.&nbsp;
                    <span className="CardInfo__important-text">We'll
                        get back to you as soon as possible!
                    </span>
                </p>
            )

            break;

        case '/terms-of-service':
            cardBodyText = (
                <p>
                    Here you will find all the information you need
                    about our membership services.&nbsp;
                    <span className="CardInfo__important-text">If you have any
                        questions or concerns, we are here to help!
                    </span>
                </p>
            )
            break;

        case '/privacy-policy':
            cardBodyText = (
                < p>
                    At Savings Storms,<span className="CardInfo__important-text">&nbsp;we take the protection of your
                        personal information very seriously.
                    </span>
                </p>
            )
            break;
        case '/refund-policy':
            cardBodyText = (
                <p>
                    If you are returning a product due to an error or it is
                    not what you wanted, follow the steps to request
                    your refund.&nbsp;<span className="CardInfo__important-text">It is a quick and easy process!</span>
                </p>
            )
            break;
        case '/about-us':
            cardBodyText = (
                <p>
                    At Savings Storms, our membership is all
                    about reducing your everyday expenses and more.&nbsp;
                    <span className="CardInfo__important-text">We're specialists in the world of smart saving! </span>
                </p>
            )
            break;

        case '/checkout':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Thank you for joining our exclusive membership
                        club. </span>Immerse yourself in luxury shopping,
                    discounted travel, blissful spa days and a world of
                    exciting perks.
                </p>
            )
            break;

        default:
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">It's just an error!</span>&nbsp;
                    What you searched for may have been moved or is
                    not available.&nbsp;
                    <span className="CardInfo__important-text">Here are a few links that may be able to help you.</span>

                </p>
            )

            break;



    }




    const goToHandle = (e) => {
        e.preventDefault();
        if (location.pathname === '/privacy-policy' || location.pathname === '/refund-policy' || location.pathname === '/terms-of-service') {
            window.location.href = `tel:${cellphone}`;
        }
        else if (location.pathname === '/about-us' || location.pathname === '/price'
        ) {
            history.push('/contact-us')
        }
        else if (location.pathname === '/') {
            if(props.cardBannerPosition === 1) history.push('/contact-us')
            else window.open('https://vip.enjoymydeals.com/', '_blank');
        }
    }
    return (

        <div className="WelcomeSection__main-card-info-container">

            <div className="WelcomeSection__main-title-container">
                <h1 className="text-left">{props.cardTitle}</h1>
            </div>
            <div className="WelcomeSection__main-body-container">
                <div>
                    {cardBodyText}
                </div>
            </div>
            {
                props.cardButtonText.trim() !== "" && (
                    <div className="WelcomeSection__main-button-container">
                        <a href="" onClick={goToHandle} className="WelcomeSection__inner-button-container">
                            {props.cardButtonText}
                        </a>
                    </div>
                )  
            }
        </div>

    )
}


export default WelcomeSection;