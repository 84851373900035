import React, { useEffect } from 'react';

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';


import mobileFriendsShopping from '../../utils/assets/checkout/mobile-friends-shopping-together-and-saving.png';
import tabletFriendsShopping from '../../utils/assets/checkout/tablet-friends-shopping-together-and-saving.png';
import desktopFriendsShopping from '../../utils/assets/checkout/onbg1.png';

const Checkout = () => {

    useEffect(()=>{
        window.scroll(0,0);
      },[]);

    const bannerSectionInfo =
    {
       cardTitle: "WE'D LOVE TO HAVE YOU",
       cardButtonText: "",
       mobileBackground : mobileFriendsShopping,
       tabletBackground : tabletFriendsShopping,
       desktopBackground : desktopFriendsShopping

    }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
