import React from "react";

import './BenefitsList.css';


import urgentCareIcon from '../../../../../utils/assets/aboutUs/icons/car-icon.png';
import primaryCareIcon from '../../../../../utils/assets/aboutUs/icons/food-icon.png';
import mentalCareIcon from '../../../../../utils/assets/aboutUs/icons/park-icon.png';
import careNavigationIcon from '../../../../../utils/assets/aboutUs/icons/shopping-icon.png';
import dermatologyCareIcon from '../../../../../utils/assets/aboutUs/icons/spa-icon.png';
import physicalTherapyIcon from '../../../../../utils/assets/aboutUs/icons/travel-icon.png';
const BenefitsList = props => {


    return (
        <div className="BenefitsList__main-container">
            <div className="BenefitsList__list-container">
                <div className="BenefitsList__first-benefits">
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={urgentCareIcon} alt="urgentCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Need to move?</h6>
                            <p className="BenefitsList__description">
                            Discounts on car rentals, gas, repairs and
                            auto parts at a fraction of the price.
                            </p>
                        </div>
                    </div>

                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={primaryCareIcon} alt="primaryCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Enjoy discounts on all your meals </h6>
                            <p className="BenefitsList__description">
                            Discover elegant restaurants, new 
                            snacks, unique offers at supermarkets 
                            and more.
                            </p>
                        </div>     
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={mentalCareIcon} alt="mentalCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Take the whole family to the best theme parks</h6>
                            <p className="BenefitsList__description">
                            Make your reservations through us 
                            and enjoy exclusive discounts on tickets
                            and attractions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="BenefitsList__second-benefits">
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={careNavigationIcon} alt="careNavigationIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Shop at the best stores</h6>
                            <p className="BenefitsList__description">
                            Find the best deals at places like Macy's,
                            Best Buy, Game Stop, and more. Save
                            while you enjoy what you love.
                            </p>
                        </div>
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={dermatologyCareIcon} alt="dermatologyCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Experience the power of wellness</h6>
                            <p className="BenefitsList__description">
                            Access more than 9,000 spa locations.
                            Enjoy massages, facials, yoga, Pilates,
                            fitness classes and more.
                            </p>
                        </div>
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={physicalTherapyIcon} alt="physicalTherapyIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Travel and see more of the world</h6>
                            <p className="BenefitsList__description">
                            Spend less on every part of your trip with
                            access to exclusive private discounts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BenefitsList;