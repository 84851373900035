import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import './Error404.css';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';


const Error404 = () => {
    

   


    return (

        <section className="Error404__main-container">
            <div className="Error404__error-container">
                <div className="Error404__error-text-container">
                    <div className="Error404__error-title-conainter">
                        <h1>Error 404</h1>
                    </div>
                    <div className="Error404__error-description">
                        <h2>Find what you need!</h2>
                        <p>
                            Get back to what you were looking for in the blink of an eye.
                            Get closer to your dream discounts by saving up to 75% on all
                            your purchases.
                        </p>
                    </div>
                </div>
            </div>
            <div className="Error404__pages-container">
                <div className="Error404__pages">
                    <Link  to='/about-us' className="Error404__about-container" >
                        <div className="Error404__about-text-container">
                            <h1>About</h1>
                        </div>
                    </Link>

                    <Link to='/price' className="Error404__price-container" >
                        <div className="Error404__price-text-container">
                            <h1>Price</h1>
                        </div>
                    </Link>

                    <Link to='/contact-us' className="Error404__contact-container">
                        <div className="Error404__contact-text-container">
                            <h1>Contact</h1>
                        </div>
                    </Link>

                    <Link to='/cancel-service' className="Error404__cancel-container">
                        <div className="Error404__cancel-text-container">
                            <h1>Cancel</h1>
                        </div>
                    </Link>


                </div>
            </div>
            <div className="Error404__contact-card-component-container">
               
                    <ContactCard />
               
            </div>
        </section>

    );
}

export default Error404;